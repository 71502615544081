import {IDescSpaceConfig} from "../model/State/IDeskSpaceSettings";
import {GET_DESC_SPACE_CONFIG, SELECT_DESC_SPACE_SETTINGS} from "../actions/descSpaceActions";

const defaultState: IDescSpaceConfig = {
    deskSpaces: undefined,
    active: undefined
};

export function deskSpaceConfigReducer(state: IDescSpaceConfig = defaultState, action: any): IDescSpaceConfig {
    switch (action.type) {
        case GET_DESC_SPACE_CONFIG:
            return action.data;

        case SELECT_DESC_SPACE_SETTINGS:
            return {
                ...state,
                active: action.data
            };

        default:
            return state;
    }
}
