import {buildCommonGridActionTypes} from "./commonGridATBuilder";
import {buildCommonEditorActionTypes} from "./commonEditorATBuider";

export const OFFERS_GRID = buildCommonGridActionTypes('OFFERS');

export const OFFERS_EDITOR = buildCommonEditorActionTypes('OFFERS');

export const OFFERS_IMAGES_EDITOR = 'IMAGES';

export const OFFERS_DETAILED_IMAGES_EDITOR = 'DETAILED_IMAGES';
