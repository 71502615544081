import WebClient from "./WebClient";
import {IPage} from "../model/IPage";
import {IDataSourceObjectKey, IDataSourceObjectItemId} from "../model/Datasources/IDataSourceObjectId";

const template = require('lodash/template');


export class DataSourceObjectsService {
    private _urls = {
        object: template('v1/DataSource/{id}/objects/{name}/data/{itemId}'),
        objects: template('v1/DataSource/{id}/objects'),
        objectsCount: template('/v1/DataSource/{id}/objects/{name}/data/count'),
        objectsData: template('/v1/DataSource/{id}/objects/{name}/data')
    };

    constructor(private _webClient: WebClient) {
    }

    getObjects(dataSourceId: number) {
        return this._webClient.get(
            this._urls.objects({id: dataSourceId})
        );
    }

    create(key: IDataSourceObjectKey, data: any) {
        return this._webClient.post(
            this._urls.objectsData(key),
            data
        );
    }

    update(key: IDataSourceObjectItemId, data: any) {
        return this._webClient.put(
            this._urls.object(key),
            data
        );
    }

    getCount(key: IDataSourceObjectKey) {
        return this._webClient.get(
            this._urls.objectsCount(key)
        );
    }

    get(key: IDataSourceObjectKey, page: IPage) {
        return this._webClient.get(
            this._urls.objectsData(key),
            page
        );
    }

    delete(key: IDataSourceObjectItemId) {
        return this._webClient.delete(
            this._urls.object(key)
        )
    }
}
