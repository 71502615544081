import React from "react"
import {addClasses} from "../services/forms/formService";

export interface IDropDownProps {
    containerElement?: 'div' | 'li'
    children: React.ReactNode
    title: string
    popupClasses?: string
}

export class DropDownComponent extends React.Component<IDropDownProps> {
    state = {
        shown: false
    };

    hide() {
        setTimeout(() => {
            this.setState({shown: false})
        }, 250);
    }

    render() {
        const {shown} = this.state;
        const {popupClasses, children, title, containerElement} = this.props;
        const classPrefix = shown ? ' show' : '';

        const content = (
            <React.Fragment>
                <button className="btn dropdown-toggle" role="button"
                        aria-haspopup="true" aria-expanded={shown}
                        onClick={() => this.setState({shown: !shown})}
                        onBlur={() => this.hide()}>
                    {title}
                </button>

                <div className={addClasses("dropdown-menu", popupClasses) + classPrefix}
                     aria-labelledby="dropdownMenuLink">
                    {children}
                </div>
            </React.Fragment>
        );

        return React.createElement(
            containerElement || 'div',
            {className: "dropdown" + classPrefix},
            content
        );
    }
}
