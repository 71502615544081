import React from "react";
import IState from "../../model/State/IState";
import {logoutAction} from "../../actions/login";
import {IGetCtx} from "../../ICtx";
import {DeskSpaceSelectorComponent} from "./DeskSpaceSelectorComponent";
import {DropDownComponent} from "../DropDownComponent";

export interface IHeaderProps {
    toggleSidebar: () => void
    cfg: IState['config']
    dispatch: any
    getCtx: IGetCtx
}

export class Header extends React.Component<IHeaderProps, any> {
    constructor(props: IHeaderProps) {
        super(props);
        this.logOut = this.logOut.bind(this);
    }

    logOut() {
        this.props.dispatch(logoutAction(this.props.cfg!.loginUrl, this.props.getCtx));
    }

    render() {
        const {toggleSidebar, cfg, getCtx} = this.props;

        return (
            <nav className="navbar navbar-expand navbar-light bg-light static-top">

                <button className="btn btn-link btn-sm text-dark order-1 order-sm-0"
                        onClick={toggleSidebar}>
                    <i className="fas fa-bars"/>
                </button>

                <a className="navbar-brand mr-1">WorkPlace</a>

                <DeskSpaceSelectorComponent getCtx={getCtx}/>

                <ul className="navbar-nav ml-auto mr-0 mr-md-3 my-2 my-md-0">
                    <DropDownComponent title={cfg!.userName!} containerElement='li' popupClasses='dropdown-menu-right'>
                        <span className="dropdown-item">{cfg!.userRole}</span>
                        <div className="dropdown-divider"/>
                        <a className="dropdown-item" role="button" href="#"
                           onClick={this.logOut}>Logout</a>
                    </DropDownComponent>
                </ul>
            </nav>
        );
    }
}
