import 'bootstrap/scss/bootstrap.scss'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/solid.css'
import './styles.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import { LayoutComponent } from "./components/layout/LayoutComponent"
import { createStore, applyMiddleware, compose, Store } from 'redux'
import { Provider, ReactReduxContext } from 'react-redux'
import thunk from 'redux-thunk';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router'
// @ts-ignore
import { createHashHistory } from 'history'
import { buildServices } from './services';
import IState from "./model/State/IState";
import ICtx from "./ICtx";
import { loadConfigAndLoginAction } from "./actions/login";
import { ReducersService } from "./services/ReducersService";
import { AppCtxContext } from "./components/AppContext";

export function buildApp({ middleware } = {} as any) {
//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
    const rootElement = document.getElementById('root');

    const history = createHashHistory();
    const reducersService = new ReducersService();
    const middlewares = [routerMiddleware(history), thunk];
    if(middleware)
        middlewares.push(middleware);


    const appStore = reducersService.createAppStoreReducer(history);
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(appStore, /* preloadedState, */ composeEnhancers(
        applyMiddleware(...middlewares))
    );

    require('lodash/templateSettings').interpolate = /{([\s\S]+?)}/g;

    const getState = () => (store.getState() as any as IState);
    const services = buildServices(store, getState, history, reducersService);

    const getCtx: () => ICtx = () => ({
        services: services,
        store: store as any as Store<IState>,
        getState: getState,
        dispatch: action => store.dispatch(action)
    });

    const ctx = getCtx();

    ReactDOM.render(
        <Provider store={store} context={ReactReduxContext}>
            <ConnectedRouter history={history} context={ReactReduxContext}>
                <AppCtxContext.Provider value={ctx}>
                    <LayoutComponent getCtx={getCtx}/>
                </AppCtxContext.Provider>
            </ConnectedRouter>
        </Provider>,
        rootElement
    )
    ;

    const startLogin = () => store.dispatch(loadConfigAndLoginAction(services) as any);

    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
        setTimeout(startLogin, 300);
    else
        startLogin();
}

if (!process.env.TEST)
    buildApp();
