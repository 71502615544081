import {GET_LOGIN_INFO} from "../actions/login";
import IState from "../model/State/IState";
import {GET_DATA, GET_DATA_FAILED, GET_DATA_SUCCESS} from "../actions/loader";

const without = require('lodash/without');


export default function (state: IState['loader'] = {loading: [GET_LOGIN_INFO]}, action: any): IState['loader'] {

    switch (action.type) {
        case GET_DATA:
            return {...state, loading: state.loading.concat(action.key)};

        case GET_DATA_SUCCESS:
            return {...state, loading: without(state.loading, action.key)};

        case GET_DATA_FAILED:
            return {
                ...state,
                loading: without(state.loading, action.key),
                error: (state.error || []).concat({type: action.key, error: action.error})
            };

        default:
            return state;
    }
}

