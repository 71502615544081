import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { configReducer } from "../reducers/configReducer";
import { deskSpaceConfigReducer } from "../reducers/deskSpaceConfigReducer";
import { toastsReducer } from "../reducers/toastsReducer";
import loader from "../reducers/loader";
import { ACTIVATE_MODULE, TEAR_DOWN_MODULE } from "../actions/modulesActions";
import { SELECT_DESC_SPACE_SETTINGS } from "../actions/descSpaceActions";
import { MEMBERS_GRID } from "../actionTypes/membersAT";
import { NEWS_GRID } from "../actionTypes/newsAT";
import { PRODUCTS_GRID } from "../actionTypes/productsAT";
import { dictionariesReducer } from "../reducers/dictionariesReducer";
import { OFFERS_GRID } from "../actionTypes/offersAT";
import { CENTERS_GRID } from "../actionTypes/centersAT";
import { COUPONS_GRID } from "../actionTypes/couponsActions";
import { MEMBER_WITH_COUPONS_GRID } from "../actionTypes/memberWithCouponsAT";
import IState from "../model/State/IState";
import { MEMBERSHIP_TYPES_GRID } from "../actionTypes/membershipTypesAT";

export class ReducersService {
    private _reducers: { [key: string]: any } = {};

    addReducer(key: string, reducer: any) {
        this._reducers[key].fn = reducer;
    }

    createAppStoreReducer(history: any) {
        const router = connectRouter(history) as any;

        return combineReducers<IState>({
            config: configReducer,
            deskSpaceConfig: deskSpaceConfigReducer,
            dictionaries: dictionariesReducer,
            router: router,
            toasts: toastsReducer as any,
            loader: loader,

            memberSearcher: this._makeReducerStub(MEMBERS_GRID.SEARCH_FOR),

            productsContainer: this._makeReducerStub(PRODUCTS_GRID.SEARCH_FOR),

            newsContainer: this._makeReducerStub(NEWS_GRID.SEARCH_FOR),

            offersContainer: this._makeReducerStub(OFFERS_GRID.SEARCH_FOR),

            centersContainer: this._makeReducerStub(CENTERS_GRID.SEARCH_FOR),

            couponsContainer: this._makeReducerStub(COUPONS_GRID.SEARCH_FOR),

            memberWithCouponsContainer: this._makeReducerStub(
                MEMBER_WITH_COUPONS_GRID.SEARCH_FOR
            ),

            membershipTypesContainer: this._makeReducerStub(
                MEMBERSHIP_TYPES_GRID.SEARCH_FOR
            ),
        });
    }

    private _makeReducerStub(key: string) {
        this._reducers[key] = {};
        return (state: any = null, action: any) => {
            const reducerCfg = this._reducers[key];
            switch (action.type) {
                case TEAR_DOWN_MODULE + "_" + key:
                case SELECT_DESC_SPACE_SETTINGS:
                    reducerCfg.incative = true;
                    return null;

                case ACTIVATE_MODULE + "_" + key:
                    reducerCfg.incative = false;
                    return reducerCfg.fn
                        ? reducerCfg.fn(undefined, action)
                        : state;
            }

            return reducerCfg.incative || !reducerCfg.fn
                ? state
                : reducerCfg.fn(state, action);
        };
    }
}
