import * as React from "react"
import './footer.scss'

export function Footer() {
    return (
        <footer className="sticky-footer bg-light">
            <div className="container my-auto">
                <div className="copyright text-center my-auto">
                    <span>Copyright © LifeVision 2019</span>
                </div>
            </div>
        </footer>
    )
}