import {IAppsLoadedNavigationCommand, IBasePath, INavigationCommand} from "../model/INavigationCommand";
import IState from "../model/State/IState";
import {IDeskSpace, IDeskSpaceSettings} from "../model/State/IDeskSpaceSettings";
import {match, matchPath} from "react-router";
import ConfigService from "./ConfigService";

const some = require('lodash/some');


export const navigationService = {
    basePathTpl: '/:key?/:id?/:module?',
    appPath: '/app/:appId',
    modulePath: '/app/:appId/:module',

    onConfigLoaded(config: IState['config']): INavigationCommand {
        if (config && config.userRole)
            return {};

        if (!config)
            throw new Error('No config loaded');

        return {
            toPath: config.userRole
                ? config.redirectUrl
                : navigationService.buildRedirectToLoginPath(config.loginUrl)
        }
    },
    onAppsLoaded(deskSpaces: IDeskSpace[], pathName: string): IAppsLoadedNavigationCommand {
        if (!deskSpaces || deskSpaces.length < 1)
            return {toPath: '/'};

        const currentPath = navigationService.matchBasePath(pathName);
        const firstDescSpaceId = deskSpaces[0].id;
        if (!currentPath || currentPath.params.key !== 'app')
            return {toPath: `/app/${firstDescSpaceId}/members`, descSpaceId: firstDescSpaceId};

        const urlAppId = parseInt(currentPath.params.id);
        if (!isFinite(urlAppId) || !some(deskSpaces, (ds: IDeskSpace) => ds.id == urlAppId))
            return {toPath: `/app/${firstDescSpaceId}/members`, descSpaceId: firstDescSpaceId};

        if (!currentPath.params.module)
            return {toPath: `/app/${urlAppId}/members`, descSpaceId: urlAppId};

        return {descSpaceId: urlAppId};
    },
    onSettingsLoaded(settings: IDeskSpaceSettings, pathName: string): INavigationCommand {
        const modules = ConfigService.extractModules(settings);
        const currentPath = navigationService.matchBasePath(pathName)!;

        if (modules.length < 1)
            return {toPath: `/app/${currentPath.params.id}`};

        if (parseInt(currentPath.params.id) !== settings.descSpaceId)
            return {toPath: `/app/${settings.descSpaceId}/members`};

        return {};
    },
    onPathValidatorRendering(deskSpaces: IDeskSpace[], settings: IDeskSpaceSettings, pathName: string): INavigationCommand {
        if (!deskSpaces || deskSpaces.length < 1)
            return {};

        const currentPath = navigationService.matchBasePath(pathName);

        const firstDescSpaceId = deskSpaces[0].id;
        if (!currentPath || currentPath.params.key !== 'app')
            return {toPath: `/app/${firstDescSpaceId}`};

        const urlAppId = parseInt(currentPath.params.id);
        if (!isFinite(urlAppId) || !some(deskSpaces, (ds: IDeskSpace) => ds.id == urlAppId))
            return {toPath: `/app/${firstDescSpaceId}`};

        if (!settings)
            return {};

        const modules = ConfigService.extractModules(settings);
        if (modules.length < 1)
            return {toPath: `/app/${currentPath.params.id}`};

        const urlModule = (currentPath.params.module || '').toLowerCase();
        if (!modules.includes(urlModule)) {
            return modules.includes('members')
                ? {toPath: `/app/${currentPath.params.id}/members`}
                : {toPath: `/app/${currentPath.params.id}/memberswithcoupons`};
        }

        return {};
    },

    buildRedirectToLoginPath(loginUrl: string) {
        return loginUrl + '?ReturnUrl=' + encodeURIComponent(window.location.href);
    },
    matchBasePath(pathName: string): match<IBasePath> | null {
        return matchPath<IBasePath>(pathName, {path: navigationService.basePathTpl});
    }
};
