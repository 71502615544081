import * as React from "react";

const attempt = require('lodash/attempt');


export function ClosePopupBtnComponent(props: { close: any }) {
    return (
        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                onClick={() => attempt(props.close)}>
            <span aria-hidden="true">&times;</span>
        </button>
    )
}