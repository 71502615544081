import WebClient from "./WebClient";
import {IPropertySettings} from "../model/State/IDeskSpaceSettings";
import {IDataSourceObjectId} from "../model/Datasources/IDataSourceObjectId";

const template = require('lodash/template');
const map = require('lodash/map');

export class DataSourceDictionariesService {
    private _urls = {
        global: template('v1/DataSource/{id}/dictionaries'),
        local: template('v1/DataSource/{id}/objects/{name}/dictionaries')
    };

    constructor(private _webClient: WebClient) {
    }

    async getAll(properties: IPropertySettings[], ds: IDataSourceObjectId) {
        const {hasLocal, hasGlobal, names} = DataSourceDictionariesService._searchForProps(properties);
        const requests: any = [];

        if (hasLocal)
            requests.push(this.getLocal(ds.id, ds.objectName));

        if (hasGlobal)
            requests.push(this.getGlobal(ds.id));

        if (requests.length < 1)
            return {dictionaries: {}, lacks: []};

        const responses = await Promise.all(requests);
        const dictionaries: any = {};
        responses.map(r => {
            map(r, (dic: any, name: string) => {
                dictionaries[name] = map(dic || {}, (value: any, key: string) => ({key, value}));
            });
        });

        const lacks: string[] = [];
        map(names, (name: string) => {
            if (!dictionaries[name])
                lacks.push(name);
        });
        return {lacks, dictionaries};
    }

    private static _searchForProps(properties: IPropertySettings[]) {
        let hasGlobal = false, hasLocal = false;
        const names = [];

        for (let prop of properties) {
            hasGlobal = hasGlobal || !!prop.dictionaryName;
            hasLocal = hasLocal || (!prop.dictionaryName && prop.isDictionary!);

            if (prop.isDictionary)
                names.push(prop.dictionaryName || prop.name);
        }
        return {hasLocal, hasGlobal, names};
    }

    getGlobal(id: number) {
        return this._webClient.get(
            this._urls.global({id})
        );
    }

    getLocal(id: number, name: string) {
        return this._webClient.get(
            this._urls.local({id, name})
        );
    }
}
