import WebClient from "./WebClient";
import {IPhoneVerifyResult} from "../model/PhoneVerifyResult";

export class PhoneService {
    private _urls = {
        checkMobile: 'v1/PhoneVerify/mobile?Phone='
    };

    constructor(private _webClient: WebClient) {
    }

    checkMobile(phone: string): Promise<IPhoneVerifyResult> {
        return this._webClient.get(
            this._urls.checkMobile + phone
        );
    }
}
