import {ICommonGridActionTypes} from "../model/State/Grid";

export function buildCommonGridActionTypes(key: string): ICommonGridActionTypes {
    return {
        SEARCH_FOR: 'SEARCH_FOR_' + key,
        SEARCH_FOR_COUNT: 'SEARCH_FOR_COUNT_' + key,
        SEARCH_FOR_SUCCESS: 'SEARCH_FOR_SUCCESS_' + key,
        DELETE: 'DELETE_' + key
    };
}
