import {ICommonEditorActionTypes} from "../model/State/ICommonEditor";

export function buildCommonEditorActionTypes(key: string): ICommonEditorActionTypes {
    return {
        SELECT: 'SELECT_' + key,
        BEGIN_CREATE: 'BEGIN_CREATE_' + key,
        EDIT: 'EDIT_' + key,
        SAVE: 'SAVE_' + key,
        CLOSE: 'CLOSE_' + key
    };
}
