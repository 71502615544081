export const TEAR_DOWN_MODULE = 'TEAR_DOWN_MODULE';
export const SET_UP_MODULE = 'SET_UP_MODULE';
export const ACTIVATE_MODULE = 'ACTIVATE_MODULE';

export function tearDownModuleAction(type: string) {
    return {type: TEAR_DOWN_MODULE + '_' + type};
}

export function activateModuleAction(type: string) {
    return {type: ACTIVATE_MODULE + '_' + type};
}
