import React from "react";
import { IGetCtx } from "../ICtx";
import { getFrom } from "../utils";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import IState from "../model/State/IState";
import { IDeskSpaceSettings } from "../model/State/IDeskSpaceSettings";
import ConfigService from "../services/ConfigService";

const get = require("lodash/get");

const modules: any = {
    members: () => import("../modules/membersModule"),
    news: () => import("../modules/newsModule"),
    products: () => import("../modules/productsModule"),
    offers: () => import("../modules/offersModule"),
    centers: () => import("../modules/centersModule"),
    coupons: () => import("../modules/couponsModule"),
    memberswithcoupons: () => import("../modules/membersWithCouponsModule"),
    membershiptypes: () => import("../modules/membershipTypesModule"),
};

export interface IModulesLoaderProps extends RouteComponentProps {
    getCtx: IGetCtx;
    activeDeskSpace: IDeskSpaceSettings;
}

export class ModulesLoader extends React.Component<IModulesLoaderProps> {
    modules: any = {};
    _activeModule: any;
    _activeDescSpaceId: number = -1;

    getModuleName() {
        return get(this.props, "match.params.module", "").toLocaleLowerCase();
    }

    getModule() {
        if (!this.props.activeDeskSpace) return null;

        const name = this.getModuleName();
        if (!name || name === "") return null;

        const userModules = ConfigService.extractModules(
            this.props.activeDeskSpace
        );
        if (!userModules.includes(name)) return null;

        if (this.modules[name]) return this.modules[name];

        if (!modules[name])
            throw new Error(`there is no module with name ${name}`);

        modules[name]().then(
            (module: any) => {
                if (this.modules[name]) return;
                this.modules[name] = module;
                module.init(this.props.getCtx);
                this.setState({ name });
            },
            () => {
                this.modules[name] = {
                    component: () => <div>Load module error.</div>,
                };
                this.setState({ name });
            }
        );
        return null;
    }

    render() {
        const module = this.getModule();
        const activeModule = this._activeModule;
        const currentDescSpaceId = getFrom(
            this,
            t => t.props.activeDeskSpace.descSpaceId
        );

        const activating =
            module !== activeModule ||
            (currentDescSpaceId != this._activeDescSpaceId &&
                module === activeModule);
        const getCtx = this.props.getCtx;

        if (activeModule && activating)
            setTimeout(() => activeModule.tearDown(getCtx));

        if (activating) {
            this._activeDescSpaceId = currentDescSpaceId;
            this._activeModule = module;
        }

        if (!module) return null;

        if (module.activate && activating)
            setTimeout(() => module.activate(getCtx));

        return React.createElement(module.component, this.props);
    }
}

export const ModulesLoaderComponent = compose(
    withRouter,
    connect((state: IState) => ({
        activeDeskSpace: state?.deskSpaceConfig?.active,
    }))
)(ModulesLoader) as any;
