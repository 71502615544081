import WebClient from "./WebClient";
import {
    IDeskSpace,
    IDeskSpaceDataSourceSettings,
    IDeskSpaceSettings,
    IPropertyEditorSettings
} from "../model/State/IDeskSpaceSettings";
import { isVisibleByRules, modulesVisibilityRules } from "./PartsVisibilityService";

const template = require('lodash/template');


export default class ConfigService {
    private _urls = {
        config: template('api/config'),
        descSpaces: template('v1/DeskSpace'),
        descSpaceSettings: template('v1/DeskSpace/{id}/settings')
    };

    constructor(private _webClient: WebClient) {
    }

    getAppConfig() {
        return this._webClient.get(
            this._urls.config()
        );
    }

    getSpacesConfigs(): Promise<IDeskSpace[]> {
        return this._webClient.get(
            this._urls.descSpaces()
        );
    }

    getSpaceSettings(descSpace: IDeskSpace): Promise<IDeskSpaceSettings> {
        // noinspection CommaExpressionJS
        return this._webClient
            .get(this._urls.descSpaceSettings(descSpace))
            .then(
                (settings: IDeskSpaceSettings) => (
                    this._fixEditorSettings(settings), settings.descSpaceId = descSpace.id, settings
                )
            );
    }

    private _fixEditorSettings(obj: IDeskSpaceSettings): IDeskSpaceSettings {
        for (let objKey in obj) {
            const ds = (obj as any)[objKey] as IDeskSpaceDataSourceSettings;
            if (!ds || !ds.properties)
                continue;

            ds.properties.forEach(prop => {
                if (!prop.editorSettings)
                    prop.editorSettings = {} as IPropertyEditorSettings;
            });
        }
        return obj;
    }

    static extractModules(settings: IDeskSpaceSettings) {
        if (!settings)
            return [];

        const isActive = isModuleActive.bind(null, settings);
        const modules = [];

        for (let name in modulesVisibilityRules)
            if (isActive(modulesVisibilityRules[name]))
                modules.push(name);

        return modules;
    }
}


function isModuleActive(settings: any, fields: string[]) {
    const mainKey = fields[0];
    const ds = settings[mainKey];
    return mainKey && ds && !ds.isHidden && isVisibleByRules(settings, fields);
}
