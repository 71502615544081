export default class WebClient {
    constructor(private _apiUrl: () => string) {
    }

    get(url: string, params?: any) {
        return this._fetch(url, 'GET', null, params);
    }

    put(url: string, data: any, params?: any) {
        return this._fetch(url, 'PUT', JSON.stringify(data), params);
    }

    post(url: string, data: any, params?: any) {
        return this._fetch(url, 'POST', JSON.stringify(data), params);
    }

    delete(url: string, params?: any) {
        return this._fetch(url, 'DELETE', null, params);
    }

    private _fetch(url: string, method: string, data: string | null, params: any) {
        const options: any = {
            credentials: "include", method: method
        };
        if (method !== 'GET') {
            options.headers = {
                'Content-Type': 'application/json'
            };
            options.body = data;
        }
        return fetch(
            this._getApiUrl() + url + this._params(params),
            options)
            .then(response => {
                if (!response.ok)
                    return Promise.reject(response);

                return response.text();
            })
            .then(text => (text.length > 0 ? JSON.parse(text) : ''));
    }

    private _getApiUrl() {
        const apiUrl = this._apiUrl();
        if (!apiUrl || apiUrl == '')
            throw new Error('config.apiUrl is required');

        return apiUrl;
    }

    private _params(params: any) {
        if (!params)
            return '';

        const esc = encodeURIComponent;
        return '?' + Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
    }
}
