export const GET_DATA = 'GET_DATA';
export const GET_DATA_FAILED = 'GET_DATA_FAILED';
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';

export function startLoadingAction(type: string) {
    return {
        type: GET_DATA,
        key: type
    };
}

export function succeedLoadingAction(type: string) {
    return {
        type: GET_DATA_SUCCESS,
        key: type
    }
}

export function failedLoadingAction(type: string, error: Error) {
    return {
        type: GET_DATA_FAILED,
        key: type,
        error
    };
}

export const notifyOnLoadingAction = (promise: Promise<any>, key: string) => (dispatch: any) => {
    promise.then(
        () => dispatch(succeedLoadingAction(key)),
        error => dispatch(failedLoadingAction(key, error))
    );
};