import {makeAspectCrop} from "react-image-crop";

const itemType = 'DeskSpaceImages';

export class ImagesService {
    constructor(private _getMainAppSiteRoot: () => string) {
    }

    async upload(files: File[]) {
        const formData = new FormData();
        formData.append('cmd', 'SaveDeskSpaceFiles');
        formData.append('itemType', itemType);

        if (files) {
            for (let i = 0; i < files.length; i++) {
                if (files[i] != null)
                    formData.append(files[i].name, files[i]);
            }
        }
        const mainSiteRoot = this._getMainAppSiteRoot();
        const response = await fetch(mainSiteRoot + 'HttpHandlers/UploadHandler.ashx', {
            method: "POST",
            body: formData,
            credentials: "include"
        });

        const filesInfos = await response.json();

        return ((filesInfos || []) as any).map((f: any) => ({
            id: f.Id,
            name: f.OriginalName,
            url: getImageUrl(mainSiteRoot, f.FileUrl),
            fileUrl: f.FileUrl
        }));
    }

    async crop({fileName, imgOptions}: { fileName: string, imgOptions: any }) {
        const mainSiteRoot = this._getMainAppSiteRoot();

        const response = await fetch(
            mainSiteRoot +
            'Services/Sites.svc/CropImage?' +
            'fileName=' + encodeURIComponent(fileName) + '&' +
            'imgOptions=' + encodeURIComponent(JSON.stringify(imgOptions)) + '&' +
            'itemType=' + itemType,
            {credentials: "include"}
        );

        const croppedFileName = await response.json();
        return getImageUrl(mainSiteRoot, croppedFileName);
    }
}

function getImageUrl(root: string, fileUrl: string) {
    return `${root}data/uploads/${fileUrl}`;
}


export function makeCenteredAspectCrop(naturalWidth: number, naturalHeight: number, aspect: number) {
    const widthIsLess = naturalWidth * aspect < naturalHeight / aspect;

    const preCrop = widthIsLess
        ? {height: 100, aspect, x: 0, y: 0}
        : {width: 100, aspect, x: 0, y: 0};

    const crop: any = makeAspectCrop(
        preCrop,
        naturalWidth / naturalHeight
    );

    if (crop.height === 100) {
        crop.x = 50 - crop.width! / 2;
    } else {
        crop.y = 50 - crop.height! / 2;
    }

    return crop;
}
