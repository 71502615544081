import React from "react";
import {connect} from 'react-redux';
import IState from "../../model/State/IState";
import LoaderComponent from '.';
import {GET_LOGIN_INFO} from "../../actions/login";
import {ModalComponent} from "../ModalComponent";

const some: any = require('lodash/some');
const Fade = require("../fade").default;


class LoaderLock extends React.Component<IState['loader']> {
    render() {
        const {loading, error} = this.props;

        const key = (loading.length > 0 ? 1 : 0)
            + ((error || []).length > 0 ? 2 : 0);

        switch (key) {
            case 0:
                return null;

            case 1: // loading something
                return <Fade><LoaderComponent/></Fade>;

            case 2: // has error
            case 3: // has error and loading

                const errors = error!.map((e, i) =>
                    <React.Fragment key={i}>
                        <dt>{e.type}</dt>
                        <dd>{e.error.toString()}</dd>
                    </React.Fragment>
                );

                return (
                    <Fade>
                        <ModalComponent head={
                            <React.Fragment>
                                <h5 className="modal-title">Error</h5>
                                {!some(error, {type: GET_LOGIN_INFO}) ?
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    : null
                                }
                            </React.Fragment>
                        }>
                            <dl>
                                {errors}
                            </dl>
                        </ModalComponent>
                    </Fade>
                );

            default:
                return null;
        }
    }
}


export const LoaderLockComponent = connect((state: IState) => state.loader)(LoaderLock);
