import * as React from "react"
import {CSSProperties} from "react";

export interface IModalProps {
    children: React.ReactNode
    head?: React.ReactNode
    footer?: React.ReactNode
    maxWidth?: string
}

export function ModalComponent(props: IModalProps) {
    const style: CSSProperties = {};
    if (props.maxWidth)
        style.maxWidth = props.maxWidth;

    return (
        <div className="modal-dialog" role="document" style={style}>
            <div className="modal-content">
                {props.head
                    ? <div className="modal-header">{props.head}</div>
                    : null}

                <div className="modal-body">
                    {props.children}
                </div>

                {props.footer
                    ? <div className="modal-footer">{props.footer}</div>
                    : null}
            </div>
        </div>
    )
}
