import * as React from "react";
import {IToastItem, IToasts} from "../../model/State/Toasts";
import "./toasts.scss"
import {removeToastItem, toastClickAction, toastsHoverAction} from "../../actions/toastsActions";
import {startTicks} from "../../services/toastsService";

const map = require('lodash/map');
const attempt = require('lodash/attempt');


export interface IToastComponentProps {
    dispatch: any
    toasts: IToasts
}


export class ToastComponent extends React.Component<IToastComponentProps> {

    onMouseEnter: () => void;
    onMouseLeave: () => void;

    stopTicks: any;

    constructor(props: IToastComponentProps) {
        super(props);

        this.onMouseEnter = this.onHover.bind(this, true);
        this.onMouseLeave = this.onHover.bind(this, false);
    }


    componentDidMount() {
        this.stopTicks = startTicks(this.props.dispatch);
    }

    componentWillUnmount() {
        attempt(this.stopTicks);
    }


    onHover(isOn: boolean) {
        this.props.dispatch(toastsHoverAction(isOn));
    }

    onClick(id: string) {
        this.props.dispatch(toastClickAction(id));
    }

    onClose(e: React.SyntheticEvent<HTMLElement>, id: string) {
        this.props.dispatch(removeToastItem(id));
        e.stopPropagation();
    }


    render() {
        return (
            <div className="toasts-container" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>

                {map(this.props.toasts.items.reverse(), (ts: IToastItem) => (
                    <div className={`toast bg-${ts.type}`} key={ts.id} onClick={() => this.onClick(ts.id!)}>
                        <div className="toast-body">
                            <button type="button" className="ml-2 close" data-dismiss="toast"
                                    aria-label="Close" onClick={(e) => this.onClose(e, ts.id!)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            {ts.message}
                        </div>
                    </div>
                ))}

            </div>
        );
    }
}
