import WebClient from "./WebClient";

export default class DataSourceService {
    private _urls = {
        DataSources: 'v1/DataSource',
    };

    constructor(private _webClient: WebClient) {
    }

    getDataSources(): Promise<any> {
        return this._webClient.get(this._urls.DataSources);
    }
}