import React from "react"
import {LoaderLockComponent} from "../loader/LoaderLockComponent"
import {connect} from 'react-redux'
import {Header} from "./Header"
import 'startbootstrap-sb-admin/scss/sb-admin.scss'
import {Footer} from "../footer";
import {IGetCtx} from "../../ICtx";
import {NawBarComponent} from "./NawBarComponent";
import IState from "../../model/State/IState";
import {ToastComponent} from "../toasts/ToastComponent";
import {IToasts} from "../../model/State/Toasts";
import {Route, withRouter} from 'react-router'
import {compose} from "redux";
import {ModulesLoaderComponent} from "../ModulesLoaderComponent";
import {PathValidatorComponent} from "./PathValidatorComponent";
import {navigationService} from "../../services/navigationService";


export interface ILayoutProps {
    getCtx: IGetCtx
    config: IState['config']
    dispatch: any
    toasts: IToasts
}

class Layout extends React.Component<ILayoutProps, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            isSideBarOpen: false
        };

        this.toggleSidebar = this.toggleSidebar.bind(this);
    }

    toggleSidebar() {
        this.setState({isSideBarOpen: !this.state.isSideBarOpen});
    }

    render() {
        const {isSideBarOpen} = this.state;
        const {dispatch, getCtx, config, toasts} = this.props;

        const content = config && config.userRole ? (
            <React.Fragment>
                <Header cfg={this.props.config} toggleSidebar={this.toggleSidebar}
                        dispatch={this.props.dispatch} getCtx={getCtx}/>
                <div id="wrapper" className={isSideBarOpen ? '' : 'sidebar-toggled'}>

                    <Route path={navigationService.appPath} render={() =>
                        <NawBarComponent isSideBarOpen={isSideBarOpen}/>
                    }/>

                    <div id="content-wrapper">
                        <div className="container-fluid">
                            <Route render={() => <PathValidatorComponent getCtx={getCtx}/>}/>

                            <Route path={navigationService.modulePath}
                                   render={() => <ModulesLoaderComponent getCtx={getCtx}/>}/>
                        </div>

                        <Footer/>
                    </div>
                </div>
            </React.Fragment>
        ) : null;


        return (
            <React.Fragment>

                {content}

                {toasts
                    ? <ToastComponent dispatch={dispatch} toasts={toasts}/>
                    : null}

                <LoaderLockComponent/>

            </React.Fragment>
        )
    }
}

export const LayoutComponent = compose(
    withRouter,
    connect((state: IState) => ({
        config: state.config,
        toasts: state.toasts
    }))
)(Layout) as any;
