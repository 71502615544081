import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { IGetCtx } from "../../ICtx";
import { compose } from "redux";
import { connect } from "react-redux";
import IState from "../../model/State/IState";
import {
    IDeskSpace,
    IDeskSpaceSettings,
} from "../../model/State/IDeskSpaceSettings";
import { GET_DESC_SPACE_CONFIG } from "../../actions/descSpaceActions";
import { GET_LOGIN_INFO } from "../../actions/login";
import { InfoPopupComponent } from "../popups/InfoPopupComponent";
import ConfigService from "../../services/ConfigService";
import { navigationService } from "../../services/navigationService";

const some = require("lodash/some");
const includes = require("lodash/includes");

export interface IPathValidatorProps extends RouteComponentProps {
    getCtx: IGetCtx;
    deskSpaces: IDeskSpace[];
    loading: string[];
    redirectUrl: string;
    activeDeskSpaces: IDeskSpaceSettings;
}

export function PathValidator(props: IPathValidatorProps) {
    if (
        props.deskSpaces &&
        props.deskSpaces.length < 1 &&
        !includesAny(props.loading, [GET_DESC_SPACE_CONFIG, GET_LOGIN_INFO])
    )
        return (
            <InfoPopupComponent
                onCancel={() => (window.location.href = props.redirectUrl)}>
                User has no any DescSpaces.
            </InfoPopupComponent>
        );

    let modules;
    if (props.activeDeskSpaces) {
        modules = ConfigService.extractModules(props.activeDeskSpaces);
        if (modules.length < 1)
            return (
                <div>
                    <div>There is no modules in current descSpace settings</div>
                    {props.deskSpaces.length > 1 ? (
                        <div>Try to select other descSpace.</div>
                    ) : null}
                </div>
            );
    }

    const navigationCmd = navigationService.onPathValidatorRendering(
        props.deskSpaces,
        props.activeDeskSpaces,
        props.location.pathname
    );
    if (navigationCmd.toPath)
        props.getCtx().services.history.push(navigationCmd.toPath);

    return null;
}

function includesAny(a: any[], b: any[]) {
    return some(a, (x: any) => includes(b, x));
}

export const PathValidatorComponent = compose(
    withRouter,
    connect((state: IState) => ({
        deskSpaces: state?.deskSpaceConfig?.deskSpaces,
        loading: state?.loader?.loading,
        redirectUrl: state?.config?.redirectUrl,
        activeDeskSpaces: state?.deskSpaceConfig?.active,
    }))
)(PathValidator) as any;
