import {IToastItem} from "../model/State/Toasts";
import {genId} from "../utils";
import {IGetState} from "../model/State/IState";

const map = require('lodash/map');
const filter = require('lodash/filter');
const isNumber = require('lodash/isNumber');

export const TOAST_ADD = 'TOAST_ADD';
export const TOAST_REMOVE = 'TOAST_REMOVE';
export const TOAST_HOVER = 'TOAST_HOVER';
export const TOAST_CLICK = 'TOAST_CLICK';
export const TOAST_TICK = 'TOAST_TICK';


export function addToastAction(toast: IToastItem) {
    toast.id = 'toast' + genId();
    return {type: TOAST_ADD, toast};
}

export function removeToastItem(id: string) {
    return {type: TOAST_REMOVE, id};
}

export function toastsHoverAction(isOn: boolean) {
    return {type: TOAST_HOVER, isOn};
}

export function toastClickAction(id: string) {
    return {type: TOAST_CLICK, id};
}

export const toastTickAction = () => (dispatch: any, getState: IGetState) => {
    const toasts = getState().toasts;
    if (!toasts || toasts.locked)
        return;

    const now = Date.now();
    const lastTick = isNumber(toasts.lastTick) ? toasts.lastTick! : NaN;
    const diff = isNaN(lastTick) ? 0 : now - lastTick;

    let items = map(toasts.items, (i: IToastItem) => ({
        ...i,
        ticks: !isNumber(i.ticks)
            ? diff
            : i.ticks! < 0
                ? i.ticks
                : i.ticks! + diff

    }));
    items = filter(items, (i: IToastItem) => i.ticks! < 4 * 1000);

    dispatch({
        type: TOAST_TICK,
        now, items
    });
};
