const some = require("lodash/some");
const includes = require("lodash/includes");
const toArray = require("lodash/toArray");
const get = require("lodash/get");

export function hasOneOf(base: any[], sub: any[]) {
    if (!base) return false;

    const predicate = includes.bind(null, sub);
    return some(base, predicate);
}

export function genId() {
    return "_" + Math.random().toString(36).substr(2, 12);
}

export function concatActionType(prefix: string = "", key: string) {
    return prefix ? `${prefix}_${key}` : key;
}

export function trimEndUnderscore(prefix: string) {
    return prefix.substring(0, prefix.length - 1);
}

export function bind<FN>(fn: FN, self: any) {
    const wf = function (this: any) {
        const args = toArray(arguments);
        return (fn as any).apply(self, [this].concat(args));
    };
    return (wf as any) as FN;
}

export function getPath<T>(
    obj: T,
    selector: (x: T) => any,
    skip = 0
): string[] {
    const path: string[] = [];
    const proxy: any = new Proxy(
        {},
        { get: (_, prop) => (path.push(prop.toString()), proxy) }
    );
    selector(proxy);
    return skip < 2 ? path : path.slice(skip - 1);
}

export function getFrom<TSrc, TProp>(
    src: TSrc,
    from: (x: TSrc) => TProp,
    def?: TProp
): TProp {
    return get(src, getPath(src, from, 1), def);
}

export function makeOnChangeReBuilder(fn: any) {
    let oldValue: any, data: any;
    return (value: any, params: any[]) => {
        if (oldValue === value) return data;

        data = fn.apply(null, params);
        oldValue = value;
        return data;
    };
}
