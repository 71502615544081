import {IServices} from "../services";
import {notifyOnLoadingAction, startLoadingAction} from "./loader";
import {IGetState} from "../model/State/IState";
import {navigationService} from "../services/navigationService";


export const GET_DESC_SPACE_CONFIG = 'GET_DESC_SPACE_CONFIG';
export const SELECT_DESC_SPACE_SETTINGS = 'SELECT_DESC_SPACE_SETTINGS';


export const getDescSpaceConfigAction = (services: IServices) => async (dispatch: any, getState: IGetState) => {
    const type = GET_DESC_SPACE_CONFIG;
    dispatch(startLoadingAction(type));
    const fn = services.config.getSpacesConfigs();

    try {
        const deskSpaces = await fn;

        const navigationCmd = navigationService.onAppsLoaded(
            deskSpaces,
            getState().router.location.pathname
        );
        if (navigationCmd.toPath)
            services.history.push(navigationCmd.toPath);

        if (!deskSpaces || deskSpaces.length < 1) {
            dispatch({type, data: {deskSpaces: []}});

        } else {
            dispatch({type, data: {deskSpaces}});
            dispatch(navigateToDeskSpaceAction(services, navigationCmd.descSpaceId!));
        }
    } finally {
        dispatch(notifyOnLoadingAction(fn, type));
    }
};


export const navigateToDeskSpaceAction = (services: IServices, deskSpaceId: number) => async (dispatch: any, getState: IGetState) => {
    const deskSpaces = getState().deskSpaceConfig.deskSpaces!;

    let currentDeskSpace = deskSpaces.find(ds => ds.id === deskSpaceId);
    if (!deskSpaces)
        throw new Error("No descSpace with id " + deskSpaceId);


    dispatch(startLoadingAction(SELECT_DESC_SPACE_SETTINGS));
    const fn = services.config.getSpaceSettings(currentDeskSpace!);

    try {
        const descSpaceSettings = await fn;

        const navigationCmd = navigationService.onSettingsLoaded(
            descSpaceSettings,
            getState().router.location.pathname
        );

        dispatch({type: SELECT_DESC_SPACE_SETTINGS, data: descSpaceSettings});

        if (navigationCmd.toPath)
            services.history.push(navigationCmd.toPath);

    } finally {
        dispatch(notifyOnLoadingAction(fn, SELECT_DESC_SPACE_SETTINGS));
    }
};
