import WebClient from "./WebClient";
import {IDataSourceObjectId} from "../model/Datasources/IDataSourceObjectId";
import {IPagination} from "../model/State/Grid";
import {IExecuteDatasourceQueryModel} from "../model/Datasources/IExecuteDatasourceQueryModel";

const template = require('lodash/template');


export default class DataSourceQueriesService {
    private _urls = {
        queryCount: template('v1/datasource/{id}/queries/count'),
        query: template('v1/datasource/{id}/queries/result/{count}')
    };

    constructor(private _webClient: WebClient) {
    }

    getQueryCount(dataSource: IDataSourceObjectId, queryModel: IExecuteDatasourceQueryModel): Promise<any> {
        return this._webClient.put(
            this._urls.queryCount(dataSource), queryModel);
    }


    getQueryData(dataSource: IDataSourceObjectId, queryModel: IExecuteDatasourceQueryModel, pagination: IPagination): Promise<any> {
        const params = {
            ...dataSource,
            count: pagination.itemsPerPage
        };
        return this._webClient.put(
            this._urls.query(params), queryModel,
            {start: pagination.itemsPerPage * pagination.nextPage!}
        );
    }
}
