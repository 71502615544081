import Fade from "../fade/index";
import {ModalComponent} from "../ModalComponent";
import * as React from "react";
import {ClosePopupBtnComponent} from "./ClosePopupBtnComponent";


export interface IInfoPopupProps {
    children: React.ReactNode
    onCancel?: () => void
}

export function InfoPopupComponent(props: IInfoPopupProps) {
    const {children, onCancel} = props;
    return (
        <Fade>
            <ModalComponent
                head={
                    <React.Fragment>
                        <h5 className="modal-title">Info</h5>

                        <ClosePopupBtnComponent close={onCancel}/>
                    </React.Fragment>
                }>
                {children}
            </ModalComponent>
        </Fade>
    )
}