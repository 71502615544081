import { IToasts } from "../model/State/Toasts";
import {
    TOAST_ADD,
    TOAST_CLICK,
    TOAST_HOVER,
    TOAST_REMOVE,
    TOAST_TICK,
} from "../actions/toastsActions";
import { fromJS } from "immutable";

const findIndex = require("lodash/findIndex");
const reject = require("lodash/reject");

export function toastsReducer(
    state: IToasts | null = null,
    action: any
): IToasts | null {
    switch (action.type) {
        case TOAST_ADD:
            return fromJS(getState(state))
                .update("items", (v: any) => v.push(action.toast))
                .toJS() as any;

        case TOAST_HOVER:
            return {
                ...getState(state),
                locked: action.isOn,
                lastTick: undefined,
            };

        case TOAST_CLICK:
            const index = getIndex(state, action);
            return index < 0 || !state
                ? state
                : (fromJS(state)
                      .setIn(["items", index, "ticks"], -1)
                      .toJS() as any);

        case TOAST_REMOVE:
            if (!state) {
                console.warn(`try to ${action.type} with empty state.`);
                return state;
            }

            const items = reject(state.items, { id: action.id });
            return items.length ? { ...state, items } : null;

        case TOAST_TICK:
            return action.items.length
                ? {
                      ...getState(state),
                      items: action.items,
                      lastTick: action.now,
                  }
                : null;

        default:
            return state;
    }
}

function getState(st: IToasts | null): IToasts {
    return st || { locked: false, items: [] };
}

function getIndex(state: IToasts | null, action: any) {
    if (!state) {
        console.warn(`try to ${action.type} with empty state.`);
        return -2;
    }

    let index = findIndex(state.items, { id: action.id });
    if (index < 0) console.warn(`try to update removed item (${action.id}).`);

    return index;
}
