import {IPropertySettings} from "../model/State/IDeskSpaceSettings";
import {IGridConfigItem} from "../model/State/Grid";
import {IGetState} from "../model/State/IState";

export const extractPropertySettings = (dictionaries: any[], prop: IPropertySettings): IGridConfigItem => ({
    ...prop,
    path: prop.name,
    paths: prop.name.split('.'),
    caption: prop.caption || prop.name,
    dictionary: getDictionary(dictionaries, prop)
});

export function getDictionary(dictionaries: any, prop: IPropertySettings) {
    if (!prop.isDictionary)
        return undefined;

    const dic = dictionaries[prop.dictionaryName || prop.name];
    return !prop.nullable
        ? dic
        : [{key: '__null__', value: 'none'}].concat(dic);
}

export function extractDeskSpaceSettings(getState: IGetState) {
    const {deskSpaceConfig} = getState();
    if (!deskSpaceConfig)
        throw new Error('state.deskSpaceConfig is required.');

    if (!deskSpaceConfig.active)
        throw new Error('state.deskSpaceConfig has no selected config.');

    return deskSpaceConfig.active;
}
