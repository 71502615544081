import WebClient from "./WebClient";

const template = require('lodash/template');

export class AuthService {
    private _urls = {
        authorization: template('api/authorization')
    };

    constructor(private _webClient: WebClient) {
    }

    logOut(){
        return this._webClient.delete(
            this._urls.authorization()
        )
    }
}
