import IState from "../model/State/IState";
import {CLEAR_DS_DICTIONARIES, LOAD_DS_DICTIONARIES} from "../actionTypes/dictionariesAT";

export function dictionariesReducer(state: IState['dictionaries'] = {}, action: any): IState['dictionaries'] {
    switch (action.type) {
        case LOAD_DS_DICTIONARIES:
            return {
                ...state,
                [action.ds]: action.dictionaries
            };

        case CLEAR_DS_DICTIONARIES:
            return {
                ...state,
                [action.ds]: null
            };

        default:
            return state;
    }
}
