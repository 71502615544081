import WebClient from "./WebClient";
import DataSourceService from "./DataSourceService";
import { IGetState } from "../model/State/IState";
import DataSourceQueriesService from "./DataSourceQueriesService";
import { DataSourceObjectsService } from "./DataSourceObjectsService";
import ConfigService from "./ConfigService";
import { AuthService } from "./AuthService";
// @ts-ignore
import { History } from "history";
import { ReducersService } from "./ReducersService";
import { ImagesService } from "./images/ImagesService";
import { getFrom } from "../utils";
import { DataSourceDictionariesService } from "./DataSourceDictionariesService";
import { PhoneService } from "./PhoneService";

const get = require("lodash/get");
const once = require("lodash/once");

export interface IServices {
    dataSource: DataSourceService;
    dataSourceQueries: DataSourceQueriesService;
    dataSourceObjects: DataSourceObjectsService;

    config: ConfigService;

    auth: AuthService;

    history: History;

    reducers: ReducersService;

    imagesService: ImagesService;

    dictionariesService: DataSourceDictionariesService;

    phoneService: PhoneService;
}

function buildServicesFn(
    store: any,
    getState: IGetState,
    history: History,
    reducersService: ReducersService
): IServices {
    const appWebClient = new WebClient(() => get(getState(), "config.apiUrl"));
    const localWebClient = new WebClient(getLocalPrefix);

    const imagesService = new ImagesService(
        once(() => getFrom(getState(), s => s.config!.mainAppSiteRoot))
    );

    return {
        dataSource: new DataSourceService(appWebClient),
        dataSourceQueries: new DataSourceQueriesService(appWebClient),
        dataSourceObjects: new DataSourceObjectsService(appWebClient),

        config: new ConfigService(localWebClient),

        auth: new AuthService(localWebClient),

        history,

        reducers: reducersService,

        imagesService,

        dictionariesService: new DataSourceDictionariesService(appWebClient),

        phoneService: new PhoneService(appWebClient),
    };
}

function getLocalPrefix() {
    return process.env.REACT_APP_LOCAL_DEV ? "http://localhost:62595/" : "/";
}

export let buildServices = buildServicesFn;

export function mockBuildServicesFn(mockFn: any) {
    buildServices = mockFn;
    return () => (buildServices = buildServicesFn);
}
