import {notifyOnLoadingAction, startLoadingAction} from "./loader";
import {IServices} from "../services";
import {IGetCtx} from "../ICtx";
import {getDescSpaceConfigAction} from "./descSpaceActions";
import {IGetState} from "../model/State/IState";
import {navigationService} from "../services/navigationService";

export const GET_LOGIN_INFO = 'GET_LOGIN_INFO';
export const RECEIVE_LOGIN_INFO = 'RECEIVE_LOGIN_INFO';
export const LOG_OUT = 'LOG_OUT';

export const loadConfigAndLoginAction = (services: IServices) => async (dispatch: any, getState: IGetState) => {
    if(hasPreload(getState)) return;

    let type = GET_LOGIN_INFO;
    dispatch({type});
    let fn = services.config.getAppConfig();
    try {
        const config = await fn;

        if(hasPreload(getState)) return;

        const navigationCmd = navigationService.onConfigLoaded(config);
        if(navigationCmd.toPath) {
            window.location.href = navigationCmd.toPath;
            return;
        }

        dispatch({type: RECEIVE_LOGIN_INFO, data: config});
        dispatch(getDescSpaceConfigAction(services));
    } finally {
        dispatch(notifyOnLoadingAction(fn, type));
    }
};


export const logoutAction = (loginUrl: string, getCtx: IGetCtx) => async (dispatch: any) => {
    dispatch(startLoadingAction(LOG_OUT));

    let fn = getCtx().services.auth.logOut();
    try {
        await fn;
        window.location.href = navigationService.buildRedirectToLoginPath(loginUrl);
    } finally {
        dispatch(notifyOnLoadingAction(fn, LOG_OUT));
    }
};


function hasPreload(getState: IGetState){
    const {config} = getState();
    return (config && config.userRole);
}
