import React from "react";
import { matchPath, RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import IState from "../../model/State/IState";
import ConfigService from "../../services/ConfigService";
import { IDeskSpaceSettings } from "../../model/State/IDeskSpaceSettings";
import { navigationService } from "../../services/navigationService";

export interface INawBarProps extends RouteComponentProps {
    isSideBarOpen: boolean;
    activeDeskSpaces: IDeskSpaceSettings;
}

export function NawBar(props: INawBarProps) {
    const match = matchPath<any>(props.location.pathname, {
        path: navigationService.modulePath,
    });

    const modules = ConfigService.extractModules(props.activeDeskSpaces);

    const links = !match
        ? null
        : modules.map(moduleName => (
              <li
                  key={moduleName}
                  className={
                      "nav-item" +
                      (match!.params.module == moduleName ? " active" : "")
                  }>
                  <Link
                      to={`${props.match.url}/${moduleName}`}
                      className="nav-link"
                      role="button">
                      <i className="fas fa-fw fa-tachometer-alt" />
                      <span>{moduleName}</span>
                  </Link>
              </li>
          ));

    return (
        <ul
            className={
                "sidebar navbar-nav bg-secondary " +
                (props.isSideBarOpen ? "" : "toggled")
            }>
            {links}
        </ul>
    );
}

export const NawBarComponent = compose(
    withRouter,
    connect((state: IState) => ({
        activeDeskSpaces: state?.deskSpaceConfig?.active,
    }))
)(NawBar) as any;
