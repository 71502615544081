const every = require("lodash/every");
const some = require("lodash/some");

export const membersTabsVisibilityRules = {
    showUps: ["visits"],
    friends: ["friends"],
    contracts: ["contracts"],
    membershipType: ["memberTypesDictionary", "userMembershipTypes"],
    coupons: ["userCoupons"],
};

export const modulesVisibilityRules: any = {
    members: [
        "users",
        {
            any: [
                { every: membersTabsVisibilityRules.showUps },
                { every: membersTabsVisibilityRules.friends },
                { every: membersTabsVisibilityRules.contracts },
                { every: membersTabsVisibilityRules.membershipType },
                { every: membersTabsVisibilityRules.coupons },
            ],
        },
    ],
    products: ["products"],
    news: ["news"],
    offers: ["loyaltyOffers"],
    centers: ["sportCenters"],
    coupons: ["couponsDictionary"],
    membershiptypes: ["memberTypesDictionary"],
};

export function isVisibleByRules(settings: any, fields: any[]) {
    return every(fields, (f: any) =>
        f.any
            ? some(f.any, (x: any) =>
                  x.every ? isVisibleByRules(settings, x.every) : settings[x]
              )
            : settings[f]
    );
}
