import IState from "../model/State/IState";
import {RECEIVE_LOGIN_INFO} from "../actions/login";

export function configReducer(state: IState['config'] = null, action: any) {
    switch (action.type) {

        case RECEIVE_LOGIN_INFO:
            return action.data;

        default:
            return state;
    }
}
