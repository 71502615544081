import {IFormFieldProps, IFormWithValidationConfig, IFormWithValidationConfigItem} from "../../model/State/Validation";
import {IEditorFieldConfig} from "../../model/State/Form";
import {IPropertySettings} from "../../model/State/IDeskSpaceSettings";
import {IKeyValue} from "../../model/State/IState";
import {getDictionary} from "../../extractors/deskSpaceSettingsExtractors";

const get = require('lodash/get');
const find = require('lodash/find');


export function getConfigItem(props: IFormFieldProps): IFormWithValidationConfigItem {
    const {form, path} = props;
    return get(form.config, path) as IFormWithValidationConfigItem;
}

export function addClasses(src: string, add: any): string | undefined {
    const sum = [];
    if (src && src.length)
        sum.push(src);

    if (add && add.length)
        sum.push(add);

    const res = sum.join(' ');
    return res.length ? res : undefined;
}


export function getFieldConfig(properties: IEditorFieldConfig[], path: string) {
    return find(properties, {path});
}


export function compareFieldsPriority(a: number = 0, b: number = 0) {
    return a === b ? 0 : a > b ? 1 : -1;
}


export function editorPropertiesToConfig(properties: IPropertySettings[], dictionary: IKeyValue[]): IFormWithValidationConfig {
    const getDictionaryLocally = getDictionary.bind(null, dictionary);
    // noinspection CommaExpressionJS
    return properties
        .filter(p => p.editorSettings.detailsView)
        .map(prop => ({
            ...prop,
            path: prop.name,
            paths: prop.name.split('.'),
            caption: prop.caption || prop.name,
            dictionary: getDictionaryLocally(prop)
        }))
        .reduce((o, p) => (o[p.name] = p, o), {} as IFormWithValidationConfig);
}
