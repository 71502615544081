import {toastTickAction} from "../actions/toastsActions";

export function startTicks(dispatch: any) {
    const bag: any = {};
    startTimeout(bag, dispatch);
    return () => {
        clearTimeout(bag.timer);
    };
}

function startTimeout(bag: any, dispatch: any) {
    bag.timer = setTimeout(() => {
        dispatch(toastTickAction());
        startTimeout(bag, dispatch);
    }, 1000)
}