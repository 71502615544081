import React from "react";
import { connect } from "react-redux";
import IState from "../../model/State/IState";
import { IDeskSpace } from "../../model/State/IDeskSpaceSettings";
import { IGetCtx } from "../../ICtx";
import { navigateToDeskSpaceAction } from "../../actions/descSpaceActions";

export interface IDeskSpaceSelectorProps {
    deskSpaces: IDeskSpace[];
    activeDeskSpacesId?: number;
    getCtx: IGetCtx;
    dispatch: any;
}

class DeskSpaceSelector extends React.Component<IDeskSpaceSelectorProps> {
    constructor(props: IDeskSpaceSelectorProps) {
        super(props);
        this.onSelect = this.onSelect.bind(this);
    }

    onSelect(event: React.ChangeEvent<HTMLSelectElement>) {
        const id = parseInt(event.target.value);
        if (isFinite(id))
            this.props.dispatch(
                navigateToDeskSpaceAction(this.props.getCtx().services, id)
            );
    }

    render() {
        return (
            <select
                className="custom-select"
                style={{ width: "200px" }}
                value={this.props.activeDeskSpacesId}
                onChange={this.onSelect}>
                {this.props.deskSpaces.map(ds => (
                    <option key={ds.id} value={ds.id}>
                        {ds.name}
                    </option>
                ))}
                {this.props.activeDeskSpacesId !== undefined ? null : (
                    <option value={undefined} />
                )}
            </select>
        );
    }
}

export const DeskSpaceSelectorComponent = connect((state: IState) => ({
    deskSpaces: state?.deskSpaceConfig?.deskSpaces || [],
    activeDeskSpacesId: state?.deskSpaceConfig?.active?.descSpaceId,
}))(DeskSpaceSelector);
